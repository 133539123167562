<template>
  <div v-if="courseDetail" class="table-row">
    <div class="row-title char-item">
      {{ charLabel }}: <b><span>{{ charValue }}</span></b>
    </div>
    <div class="buttons">
      <a
        class="edit"
        :href="`/Dashboard/Coach/${coachId}/CoachDetails/EditCourseCharacteristic/${characteristic.id}/`"
        :aria-label="`Edit characteristic: ${charLabel}`"
      >
        <span class="tooltip-text">Edit characteristic</span>
      </a>
    </div>
  </div>
  <div v-else class="char-item overflow-path">
    <div class="truncate">{{ charLabel }}: <b><span>{{ charValue }}</span></b></div>
    <span class="tooltip-text">{{ charLabel }}: <b><span>{{ charValue }}</span></b></span>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useCharacteristicsStore } from "../../stores/characteristics.js";

export default {
  name: "CharacteristicRow",
  data() {
    return {};
  },
  props: {
    characteristic: {
      type: Array,
      required: true,
    },
    courseDetail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useCharacteristicsStore, ["coachId"]),
    charLabel() {
      return this.characteristic.label;
    },
    charValue() {
      return this.characteristic.value ? this.characteristic.value : "not wired";
    },
  },
};
</script>
